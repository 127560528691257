import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Layout } from '../components/Layout'
import { ReferencesList } from '../components/ReferencesList'
import { ContactSection } from '../components/Sections'

// import Image from '../components/Image'
import SEO from '../components/seo'

const HeadlineSection = styled.header`
  margin: 2rem 0 4rem;

  @media screen and (min-width: 992px) {
    margin: 4rem 0 8rem;
    width: 60%;
  }

  @media screen and (min-width: 1200px) {
    margin: 4rem 0 8rem;
    width: 50%;
  }
`

type Props = {
  data: unknown
}

const PortfolioPage = ({ data }: Props) => {
  const {
    contentfulSeite: { lead, subtitle, sections },
  } = data

  const { references } = sections[0]
  const orderedReferences = references
    .sort((a, b) => {
      return b.year - a.year
    })
    .sort((a, b) => b.isHighlight - a.isHighlight)

  return (
    <Layout>
      <SEO title="Current work" />

      <div className="pt-16">
        <div className="sm:px-0 mx-auto sm:max-w-xl md:max-w-2xl lg:max-w-3xl">
          <div className="sm:w-3/4">
            <h1 className="font-accent text-gray-900 text-3xl sm:text-4xl mb-5">
              {lead}
            </h1>
            <p>{subtitle}</p>
          </div>

          <ReferencesList references={orderedReferences} />

          <ContactSection />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    contentfulSeite {
      id
      title
      lead
      subtitle
      sections {
        id
        references {
          id
          name
          subline
          description
          isHighlight
          private
          projectWebsite
          year
          projectWebsite
          skills {
            id
            name
          }
          platforms {
            id
            title
          }
          via {
            id
            name
            website
          }
          bild {
            id
            gatsbyImageData(layout: CONSTRAINED, placeholder: DOMINANT_COLOR)
          }
        }
      }
    }
  }
`

export default PortfolioPage
